import React from "react";
import AppHeader from "../component/AppHeader/AppHeader";
import favicon from "../images/favicon.png";
import { Helmet } from "react-helmet";
import { overall, content2 } from "./ResumePage.module.css";

const technicalanimation = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} />
        <title>Ashley Kim | Technical Animation Blog</title>
      </Helmet>
      <div className={overall}>
        <AppHeader />
        <div className={content2}>
          <h1>15-464 Technical Animation Blog</h1>
          <h2>January 18, 2023 (Wed)</h2>
          <p>
            I'm excited for this class, especially for the special effects
            aspect of the course. My main interest lies in special effects,
            interactive design, and immersive media. I look forward to learning
            more about the state of art technical animation tools and algorithms
            and potentially apply or use those tools in my personal
            practice/projects.
          </p>

          <h2>January 23, 2023 (Mon)</h2>
          <p>
            Going over walk cycle and motion capture was a good review for me,
            as the last time I worked with these was last spring semester (S22).
            Although I've seen many of the applications of motion capture data,
            I haven't really looked into the data file itself before. Looking
            into what the motion capture data file looks like made me appreciate
            once again of the already developed opensource parsers.
          </p>

          <h2>January 25, 2023 (Wed)</h2>
          <p>
            Learning about different implementation methods for IK in class
            today was highly valuable to me. All I knew regarding Jacobian was
            the basics that we briefly covered in Computer Graphics course, so
            walking through the paper on different applications of Jacobian and
            pseudoinverse was very helpful. Perhaps I can try implementing these
            for the second part of the first mini project?
          </p>

          <h2>January 30, 2023 (Mon)</h2>
          <p>
            It was interesting to see how Jacobian transpose relates to gradient
            descent. However, I don't know if I fully understand the transition
            from the equation we covered last class to the one we covered today,
            as I don't see how subtracting the offset gives similar result. Or
            was it suppose to mean that the gradient descent gives higher
            accuracy because it's subtracting the offset amount of a part that
            shouldn't be shifting? I also realized during today's class that I
            was misunderstanding the use of rotational axis in last class when
            it's used to cross with the distance from joint to end point (r_i).
            I appreciate how material we cover in class build onto each other
            instead of introducing new topic each time. This way gives me time
            to sit with the topic we covered in class and review again during
            the following class to strengthen understanding. Also, it
            assimiliates how researches are done (i.e. build onto previously
            released paper and compare & contrast).
          </p>

          <h2>February 1, 2023 (Wed)</h2>
          <p>
            To me, rigging and skinning always have been the 'required' steps
            that I just had to do to get to the fun animation part. Learning
            about RigNet and Neural Blend Shapes for rigging and skinning was
            useful, as it seemed highly practical and time-saving. I still
            wonder how these models were trained, specifically how blend shapes
            are predicted. Based on my previous experience, blend shapes still
            rely on the initial rigging and skinning that's done on the rest A
            or T pose of the character, then set a certain pose as the end pose
            for the blend shape. However, the Neural Blend Shapes paper seemed
            to imply that these blend shapes can be trainned in a different
            manner than how RigNet trains to predict where the rig would be
            located and mesh weights would be distributed. Although my main
            interest lies in visual effects and simulation, I think it would be
            interesting to further look into the papers on automated rigging and
            skinning.
          </p>

          <h2>February 6, 2023 (Mon)</h2>
          <p>
            Motion capture lab visit was a good review session, although since
            it was my third time there as a class, I wasn't surprised with
            anything new. I'm still unclear if I would use motion capture lab
            for the final project, but I'm glad to know that it's available as
            an option if I need to capture any motion. Learning about Arjun's
            paper on figuring out the hand gesture by area indication on mesh
            was highly interesting, as I haven't thought about defining a hand
            gesture based on a contact area between the two mesh. I think in
            general learning about what techniques are out there opens up the
            perspective on how one can see the same problem form different
            angles and focus.
          </p>

          <h2>February 8, 2023 (Wed)</h2>
          <p>
            First paper presentations today. Again, I find these paper
            presentations really crucial and useful, as I get to have exposures
            to various techniques out there without having to fully dive into
            the paper and all the math. The non-Euclidean space paper was highly
            interesting, as I've seen its applications across various video
            games but didn't really know much about the concept and math behind
            it. I also wouldn't have known about it since I couldn't tell that
            the paper discussed such a topic only from the title. (i.e. I didn't
            know such portal effect and dimension changing was called
            non-Euclidean space...)
          </p>

          <h2>February 13, 2023 (Mon)</h2>
          <p>
            As also an art student, I think having a time dedicated for sharing
            works is useful for future projects. Although it wasn't a crit
            session for people to give ideas on how to improve on the project,
            viewing others' works gave me a good sense of what the individual
            strengths of this class is. It was also a session that reminded me
            of how the artists and the developers view and operate the tools
            differently.
          </p>

          <h2>February 15, 2023 (Wed)</h2>
          <p>
            I don't think I ever looked into how cloth simulation works behind
            the scene. It was quiet a fresh idea for me to see that the cloth
            simulation is a collection of spring phyiscs intertwined. I see how
            this would intuitively work since cloth also has a tendancy to go
            back to its original stable state upon stretching it out. I wonder
            who first came up with this idea to simulate cloth.
          </p>

          <h2>February 20, 2023 (Mon)</h2>
          <p>
            I think cloth collision is one of the effects/simulations that can
            convey so many different types by just tweeking a few paramenters.
            There exits so many types and feels of fabric in this world, and I
            think that's what makes cloth simulation both the hardest and the
            easiest to make. As long as we make sure the cloth doesn't get
            caught in the movement of the rig and creates an artifact, cloth
            simulation can already look somewhat natural enough to convey the
            idea of cloth.
          </p>

          <h2>February 22, 2023 (Wed)</h2>
          <p>
            Talking about different ways of collision offset/detection methods
            reminded me of Computer Graphics light ray and particle collision
            parts. Since we already went over and had to implement as part of
            the Computer Graphics course, checking whether collision has
            occurred and going forward/back in the timestep to place the object
            at the boarder was the most straightforward idea to understand. I
            also happened to make
            <a href="https://www.instagram.com/kitetale/">
              a few of rigid-body collision effects in Houdini
            </a>
            , and I was very surprised by how accurate Houdini's code dealt with
            collision.
          </p>

          <h2>February 27, 2023 (Mon)</h2>
          <p>
            Among the papers presented today, I found the cloth prediction one
            very interesting. I learned so much through this class that Neural
            Network has been the state of the art method for various types of
            simulations and animation techniques. It really reminded me of how
            AI is integrating into all industries to improve the techniques.
            Yarn-level cloth simulation was also cool to learn about, as I
            haven't really thought about how different patterns of weaving
            influence how the overall cloth interacts with itself and other
            objects. I'm not sure if it's a trend in CMU or everywhere, but I
            also have noticed that a lot more people are now interested in the
            algorithmic textile.
          </p>

          <h2>March 1, 2023 (Wed)</h2>
          <p>
            Seeing previous final projects today was so intriguing and
            inspiring. I was not only able to understand what's expected for the
            final project, but also guage the diversity in topics and methods
            that I could implement for the final project. I think I want to do
            some sort of particle simulation, probably particle simulation that
            mimics fluid simulation. The water ripple one and rigid body contact
            seemed very fun to play with and relatively easy to implement. Snow
            simulation was also interesting -- I remember seeing snow simulation
            test videos for Frozen from Art, Animation, and Technology class.
            Perhaps I could do a wrecking ball/angry bird collision on snow-like
            particles. I'm still not sure what I would be working on for the
            final project, so my plan would be coming up with several ideas and
            narrow it down to one during the meeting with professor.
          </p>

          <h2>March 13, 2023 (Mon)</h2>
          <p>
            I just flew in from Ithaca this morning and came straight to the
            class. I thought I'd be missing this class, but I was able to find
            an alternative flight that would let me be here in time for class!
            I'm excited for my final project; I was afraid the idea I liked the
            most (applying real-time Kinect depth mocap data to the character
            like what Nick did before) might be out of scope for the class, but
            I'm glad to have the professor's confirmation that it's within the
            scope. I may have to flesh out my idea more, but I'm planning to use
            openFrameworks to apply mocap data from Kinect's depth image to a 3d
            character. Perhaps I can use GLSL to make the 3D character? I was
            extremely impressed when I watched Inigo Quilez's
            <a href="https://www.youtube.com/watch?v=8--5LwHRhjk&ab_channel=InigoQuilez">
              {" "}
              "Painting a Character with Maths"
            </a>{" "}
            or{" "}
            <a href="https://www.youtube.com/watch?v=Cfe5UQ-1L9Q&ab_channel=InigoQuilez">
              {" "}
              "Happy Jumping"
            </a>{" "}
            animation live coding video. Perhaps I could pull some math
            equations from these to construct my own ( simpler) character for
            the final project. I'm thinking to have both live demo and
            documentation video for the presentation.
          </p>

          <h2>March 15, 2023 (Wed)</h2>
          <p>
            Although I have decided to work with Kinect for my final project,
            hearing about others' final project ideas and references was quiet 
            inspiring and useful. I'm planning to read more about certain papers
            others mentioned, such as rigid body fracture based on pressure, for
            my senior art capstone project that I'll be presenting at the senior
            art show at Miller ICA at the end of the semester. 
            Otherwise, I'm excited to learn more about fluid simulation. It's 
            just so mindblowing to see how much graphcis technology has developed
            over time -- Water simulation in Avater II was extremely realistic,
            and it's always impressive to see and realize that the first 'good-looking'
            fluid simulations only happened not so long ago. Sometimes I feel like
            the world has invented so many algorithms and graphical techniques 
            while I was just growing up... So much has happened since 2000, if not 
            even before.
          </p>

          <h2>March 20, 2023 (Mon)</h2>
          <p>
            I enjoyed sharing my hard work of not only implementing interactive live webpage cloth simulation but also all the simulation engines comparisons! As I mentioned before, I'm currently working on getting a simple simulation scene done each day (hopefully..) using existing engines and upload them on instagram (@kitetale). This project helped me decide what to try for my side personal instagram simulation experimentation streak!
          </p>

          <h2>March 22, 2023 (Wed)</h2>
          <p>
              Today we covered more of Navier-Strokes method, which helped me a lot in understanding the paper I'm reading for my paper presenation. Since the one I chose to present is about white water and producing realistic foams & bubbles, it was referring a lot to the previous fluid simulation methods that I had no clue what each was. Now that I understand how Navier-Strokes work for bulk fluid simulation, I should go read the white water paper again to see if I can understand better what they're referring to when describing their algorithm.
          </p>

          <h2>March 27, 2023 (Mon)</h2>
          <p>
              More fluid algorithm was covered today, and I'm glad to learn about the lagrangian SPH method because that was the next often mentioned algorithm after Navier-Strokes in the white water paper I'm reading for the presentation. Sometimes I realize and feel shocked again and again on how everything in the world can be expressed in math.. No wonder some people argue that our world is a simulation.
          </p>

          <h2>March 29, 2023 (Wed)</h2>
          <p>
              I thought today's paper presentation order was really well selected.
              People who presented after me each highlighted the aspects that the paper I presented shared 
              as the downside/limitation of the algorithm introduced in the paper. 
              For instance, my whitewater paper was written under the assumption
              that all bubbles and foam are 'wet', meaning they cannot be stacked 
              or interact with one another other than influnecing each other's 
              position. The following person then presented the paper on 'dry', stackable foam.
              The person after presented the water donut effects, which was omitted in my paper.
          </p>

          <h2>April 3, 2023 (Mon)</h2>
          <p>
              I was able to understand better of MAC grid and the P2G and G2P methods
              now that I've read its application in the paper that I presented. Also, seeing how there are various
              kinds, types, and movements in fluid simulation is so mindblowing -- Fluid simulation isn't just limited
              to bulk fluid simulation, but can be narrowed down to a smaller scaled local simulation of fluid particles
              to simulate more realistic flow and fall through varying sized objects.
          </p>

          <h2>April 5, 2023 (Wed)</h2>
          <p>
              Papers presented today ranged across various topics beyond cloth and fluid simulation.
              MPM fracture paper reminded me of the solver that Houdini provided for fracture, although
              for Houdini, I think the user had to manually set where the fractured pieces would be at in 
              what shape/size.

              Deformable Objects animation by keyframing the rest shape is a good algorithm/paper 
              to keep in mind as I work on my final project. Thermomachenical Fracture for baking 
              looked very realistic, and was a good opportunity to rethink how fracture simulation
              can be applied in different situations.
          </p>
          <h2>April 10, 2023 (Mon)</h2>
          <p>
              Papers on deformable bodies animation to portray physically realistic motion from the 90s really was impressive considering the state of the art of graphics at the time. It also specifically reminded me of this abstract animation piece that was also made for the Olympic, called <a href="https://vimeo.com/38017188">Forms</a>. In this work, the artists used mocap data of althetes in their action during the game to reinterpret using abstract generative geometry. I find this progression of ways to portray athletic movements very intriguing, as it definitely lies within the realm of Computer Science and Art. (Artistic interpretation of human motion using graphics!)
          </p>
          <h2>April 12, 2023 (Wed)</h2>
          <p>
              The paper that Grace presented called <a href="https://tianxintao.github.io/get_up_control/">"Learning to Get Up</a> approached skeletal motion prediction in a new way that I haven't seen or thought of before. I often thought the target lies on the character's feet to ensure that the model is standing firmly on the floor, not floating nor penetrating it. This paper, on the other hand, sets the target to the head, predicting how the skeletal movements would be given the head should be ending up at the target point. I also thought this paper can also be used in other fields like medical emergency or any human-life related emergency situation education simulation, as the strong simulation would do the work of visualizing the optimal way for one to get up from an arbitrary fallen state (something we can't test with actual people since we can't drop people from high heights arbitrary..).
          </p>

          <h2>April 17, 2023 (Mon)</h2>
          <p>
              Learning more about generating skeletal animation based on input set of motion capture data was highly intriguing to me. My final project also relates to generating armature animation, and now that I'm working on it, I'm now noticing how wide this area of animating using neural network is. Although my project is more like live puppetry based on skeleton structure extracted from camera input, learning about Motion VAE (MVAE) helped me think more about various potentials I can do with skeleton data.
          </p>

          <h2>April 19, 2023 (Wed)</h2>
          <p>
              Papers presented today introduced other ways to track and generate skeletal animation based on mocap data. The paper on VR headset/controller three point tracking was surprisingly more accurate in terms of motion prediction given that it only takes one or three tracking points (headset, two hand-held controllers). 

              The crowd simulation paper made me think how crowd simulation also involves movement trends algorithm (which the presented paper introduced). Previously, I've seen papers that address how one can diversify the individual crowd motion animation by interpolating and merging multiple mocap data. Perhaps games like Hitman and animation films do use both aspects to diversify both movement trend and individual animations.
          </p>
          
        </div>
      </div>
    </div>
  );
};

export default technicalanimation;
